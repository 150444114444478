import type { UseLayerConductorReturn, LayerData, LayerID } from '@/types/modal';
import { hideOnlineChat, showOnlineChat } from '~/helpers/onlineChatHelper';

const LayerStore: LayerData<any>[] = reactive([]);

let lastLayerID = 0;
const getNewLayerID = () => ++lastLayerID;
const isLastLayer = (id: LayerID) => LayerStore[LayerStore.length - 1]?.id === id;

export const useLayerConductor = (): UseLayerConductorReturn => {
  const addLayer = async (layerData: LayerData<any>) => {
    LayerStore.push(markRaw(layerData));
    await nextTick();
  };

  const removeLayer = async (id: LayerID) => {
    LayerStore.forEach((layer, index) => {
      if (layer.id === id) {
        LayerStore.splice(index, 1);
      }
    });
    await nextTick();
  };

  const removeAll = () => {
    LayerStore.splice(0);
  };

  watch(LayerStore, list => {
    if (list.length) hideOnlineChat();
    else showOnlineChat();
  });

  return {
    LayerStore,
    addLayer,
    removeLayer,
    removeAll,
    getNewLayerID,
    isLastLayer,
  };
};
