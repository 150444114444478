<script lang="ts" setup>
  import type { RouteLocationNamedRaw, RouteRecordNameGeneric } from 'vue-router';
  import VProfileMenu from '../header/VProfileMenu.vue';
  import { useScrollLock } from '@vueuse/core';
  import { useToggle } from '@vueuse/shared';
  interface ITabs {
    name: string;
    to: RouteLocationNamedRaw;
    icon: string;
    action?: Function;
  }

  const showMenu = ref(false);
  const route = useRoute();
  const { afterEach } = useRouter();
  const { state } = storeToRefs(useAuthStore());
  const { onReveal } = useAuth();
  const documentLock = useScrollLock(document?.body);
  const toggleLock = useToggle(documentLock);
  const checkActive = (routeName: RouteRecordNameGeneric) => String(route.name).includes(String(routeName));

  const tabs: ITabs[] = [
    { name: 'Главная', icon: 'home', to: { name: 'home' } },
    { name: 'Каталог', icon: 'browse', to: { name: 'browse' } },
    { name: 'Избранное', icon: 'bookmark', to: { name: 'favorites' } },
  ];

  afterEach(() => {
    if (showMenu.value) {
      showMenu.value = false;
      toggleLock();
    }
  });
  const clickToProfileMenu = () => {
    showMenu.value = !showMenu.value;
    toggleLock();
  };
</script>

<template>
  <nav class="control-bar">
    <nuxt-link
      class="control-bar__link"
      :class="{ active: checkActive(tab.to.name) }"
      v-for="(tab, key) in tabs"
      :key="key"
      :to="tab.to"
    >
      <component :is="`icon-${tab.icon}`" />
      {{ tab.name }}
    </nuxt-link>
    <div class="control-bar__link" :class="{ active: showMenu || checkActive('profile') }">
      <template v-if="state.loggedIn">
        <v-user type="avatar" size="small" tag="span" @click="clickToProfileMenu" />
        <v-profile-menu
          v-if="showMenu"
          class="control-bar__menu"
          use-image-loader
          :is-profile="checkActive('profile')"
        />
        Профиль
      </template>
      <template v-else>
        <component :is="'icon-profile'" @click="onReveal" />
        Войти
      </template>
    </div>
  </nav>
</template>

<style lang="scss">
  .control-bar {
    display: none;
    height: 64px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 99998;
    background: $bg-system;
    color: $text-color-3;
    svg {
      width: 24px;
      height: 24px;
    }
    &__link {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      &.active {
        color: $text-color-secondary;
      }
    }

    @media (max-width: $tablet) {
      display: flex;
      &__menu {
        position: fixed;
        top: 0;
        left: 0;
        overflow-y: scroll;
        height: 100vh;
        height: calc(var(--app-height) - 64px);
        width: 100vw !important;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, max-content);
        align-items: flex-start;
        gap: 16px !important;
        border-radius: 0 !important;
        padding-bottom: 0 !important;
        overflow: auto;
      }
      .user__edit-button {
        padding: 0;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
</style>
