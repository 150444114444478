import type { RouterConfig } from '@nuxt/schema';
import { filterRoutesByExcludes } from '~/helpers/common';

const excludes = ['home', 'components'];

// https://router.vuejs.org/api/interfaces/routeroptions.html#routes
export default <RouterConfig>{
  routes: _routes => [
    ...filterRoutesByExcludes(_routes, excludes),
    {
      name: 'home',
      path: '/',
      component: () => import('~/pages/home/Index.vue').then(r => r.default || r),
    },
    { path: '/terms', redirect: 'policy-user' },
    { path: '/agreement-18', redirect: 'policy-agreement' },
    { path: '/copyright_docs', redirect: 'policy-copyright' },
    { path: '/right-holder-policy', redirect: 'policy-holder' },
    { path: '/tariffs', redirect: 'subscriptions' },
  ],
  scrollBehavior(to, from, savedPosition) {
    const nuxtApp = useNuxtApp();
    const { start, finish } = useLoadingIndicator();
    let position = savedPosition || { top: 0, left: 0 };

    if (to.path === from.path) {
      if (nuxtApp.isHydrating) return false;

      if (to.hash) {
        return { el: to.hash, top: _getHashElementScrollMarginTop(to.hash), behavior: 'smooth' };
      }

      new Promise(resolve => setTimeout(resolve)).then(() => {
        start();
        setTimeout(finish, 500);
      });

      return { top: 0, left: 0, behavior: 'smooth' };
    }

    return new Promise(resolve => {
      nuxtApp.hooks.hookOnce('page:finish', async () => {
        await new Promise(resolve2 => setTimeout(resolve2, 0));
        if (to.hash) {
          position = { el: to.hash, top: _getHashElementScrollMarginTop(to.hash), behavior: 'smooth' };
        }
        resolve(position);
      });
    });
  },
};

function _getHashElementScrollMarginTop(selector) {
  try {
    const elem = document.querySelector(selector);
    if (elem) {
      return (
        (Number.parseFloat(getComputedStyle(elem).scrollMarginTop) || 0) +
        (Number.parseFloat(getComputedStyle(document.documentElement).scrollPaddingTop) || 0)
      );
    }
  } catch {}
  return 0;
}
