import { defineStore } from 'pinia';
import type { IUser, IFetchUser, IAuthState, ICookieCredits } from '~/types/common';
import type { CookieRef } from '#app';
import { accountLogout, getAccountDetails } from '~/services/modules/auth.service';
import useStatefulCookie from '~/composables/useStatefulCookies';
import { useRedirect } from '~/composables/useRedirect';
import { useRouter } from 'vue-router';
import { getTimeRemaining } from '~/helpers/datetime';

export const useAuthStore = defineStore('useAuthStore', () => {
  const router = useRouter()

  const credits: CookieRef<ICookieCredits> = useStatefulCookie('credits', {
    default: () => ({ access: null, refresh: null }),
  });

  const config = useRuntimeConfig();
  const state = reactive<IAuthState>({
    loggedIn: false,
    token: credits.value?.access ?? null,
    refresh: credits.value?.refresh ?? null,
  });
  const user = ref<IUser | null>(null);
  const subscription = computed(() => user.value?.subscription);
  const subscriptionActive = computed(() => subscription.value?.is_active);
  const userActiveRecurrentCard = computed(() => user.value?.recurrent_card?.id.toString() ?? '');

  const isSpecialTariff = computed(() => subscription?.value?.tariff_title === 'Тестовый');
  const isCatalogRedesignEnabled = computed(() => isSpecialTariff.value);
  const subExpirationDate = computed(() => {
    if (user.value?.subscription?.status !== 'paid') return 0;
    return getTimeRemaining(user.value.subscription?.expiration_date);
  });

  const fetchUser = async () => {
    if (!state.token && config.public.isLanding) return;
    try {
      const userData = await getAccountDetails();
      setUser(userData);
      state.loggedIn = true;
    } catch (e) {
      $resetState();
      console.error(e);
    }
  };
  const setUser = (userData?: IUser) => {
    if (!userData) return;
    user.value = userData;
  };
  const setState = (data: IFetchUser) => {
    state.loggedIn = true;
    state.token = data.token;
    state.refresh = data.refresh_token;
  };
  const setCreditsCookies = (data: IFetchUser) => (credits.value = { access: data.token, refresh: data.refresh_token });
  const logout = async () => {
    try {
      await accountLogout();
      await fetchUser();
      user.value = null
      await router.push('/')
    } catch (e) {
      console.error(e);
    }
  };
  const $resetState = () => {
    state.loggedIn = false;
    state.token = null;
    state.refresh = null;
  };

  return {
    state,
    user,
    subscription,
    subscriptionActive,
    userActiveRecurrentCard,
    isSpecialTariff,
    isCatalogRedesignEnabled,
    subExpirationDate,
    setUser,
    setState,
    setCreditsCookies,
    fetchUser,
    logout,
    $resetState,
  };
});
