<script lang="ts" setup>
  import VFooterItem from '~/components/footer/VFooterItem.vue';
  import VFooterStores from '~/components/footer/VFooterStores.vue';
  import VLogo from '~/components/header/VLogo.vue';
  import { getStoresInfo } from '~/services/modules/utility.service';
  import type { IFooterItem } from '~/components/footer/types';

  const { data, suspense } = getStoresInfo();
  onServerPrefetch(async () => await suspense());

  const menu = computed<IFooterItem[]>(() => [
    {
      header: 'Разделы',
      list: [
        {
          name: 'Каталог',
          route: { name: 'browse' },
        },
        {
          name: 'Избранное',
          route: { name: 'favorites' },
        },
        {
          name: 'Спорт',
          route: { name: 'sports-channels' },
        },
        {
          name: 'Тарифы',
          route: { name: 'subscriptions' },
        },
        {
          name: 'FAQ',
          route: { name: 'faq' },
        },
        {
          name: 'Подборки',
          route: { name: 'collections' },
        },
      ],
    },
    {
      header: 'О нас',
      list: [
        {
          name: 'Пользовательское соглашение',
          route: { name: 'policy-user' },
        },
        {
          name: 'Политика конфиденциальности',
          route: { name: 'policy-private' },
        },
        {
          name: 'Соглашение правообладателя',
          route: { name: 'policy-holder' },
        },
      ],
      button: {
        name: 'Документы правообладателя',
        route: { name: 'policy-copyright' },
      },
    },
    {
      header: 'Поддержка',
      list: [
        {
          name: 'support@kinoflex.ru',
          route: 'mailto:support@kinoflex.ru',
          icon: 'mail',
        },
      ],
      // button: {
      //   name: 'Написать в чат',
      //   route: '/',
      // },
    },
    {
      type: 'row',
      list: [
        {
          route: String(data.value?.vk_group_link),
          icon: 'vk',
        },
        {
          route: String(data.value?.tg_channel_link),
          icon: 'telegram',
        },
        {
          route: String(data.value?.dzen_channel_link),
          icon: 'zen',
        },
      ],
    },
  ]);
  const isTargetKgDomain = useState('isTargetKgDomain');
</script>

<template>
  <footer class="footer container">
    <template v-if="!isTargetKgDomain">
      <div class="footer__top">
        <v-logo img link size="middle" class="footer__top-logo" />
        <div>
          <v-footer-item :item="menu[3]" />
          <div />
        </div>
      </div>
      <div class="footer__sections">
        <v-footer-item v-for="(item, index) in menu" :key="index" :item="item" />
      </div>
    </template>
    <div class="footer__divider"></div>
    <v-footer-stores />
    <div class="footer__bottom">
      <div class="footer__bottom-left">
        <div class="footer__notes">
          Просматривая материалы, находящиеся на данном сайте, Вы обязуетесь выполнять условия
          <router-link :to="{ name: 'policy-user' }">пользовательского соглашения</router-link>
        </div>
      </div>
      <div class="footer__bottom-right">
        <v-logo img link />
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
  .footer {
    padding: 40px 0 24px;

    &__top {
      display: none;
      margin-bottom: 38px;
      @media (max-width: 540px) {
        display: flex;
        justify-content: space-between;
      }
    }

    &__top-logo {
      padding: 0;
    }

    &__sections {
      display: grid;
      grid-template-columns: auto auto auto 1fr;
      grid-gap: 104px;
      margin-bottom: 42px;

      @media (max-width: $tablet-large) {
        grid-gap: 80px;
      }
      @media (max-width: $tablet) {
        grid-gap: 64px;
        grid-template-columns: auto auto auto;
      }
      @media (max-width: $tablet-small) {
        grid-gap: 64px;
        grid-template-columns: auto auto;
      }
      @media (max-width: $retina) {
        grid-gap: 16px;
      }

      @media (max-width: 540px) {
        grid-gap: 40px;
        grid-template-columns: 1fr;
      }
    }

    &__divider {
      height: 1px;
      width: 100%;
      background-color: $secondary_grey_mid;
      margin: 32px 0;
    }

    &__bottom {
      padding: 32px 0 0;
      display: flex;
      justify-content: space-between;
    }

    &__notes {
      color: $text-color-4;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      a {
        color: $text-color-secondary;
      }
    }

    @media (max-width: $mobile-large) {
      &__sections {
        display: grid;
        gap: 24px;
      }
    }
    @media (max-width: $retina) {
      margin-bottom: 64px;
      &__bottom {
        text-align: center;
      }
      &__bottom-right {
        display: none;
      }
    }
  }
</style>
