<script setup lang="ts">
  import VButton from '~/components/ui/VButton.vue';
  import { getMyNotifications } from '~/services/modules/profile.service';

  const { data, suspense } = getMyNotifications();

  onServerPrefetch(async () => await suspense());

  const hasUnviewedNotification = computed(
    () => data.value?.some(notification => !notification.was_viewed && notification.icon_type === 'cover') || false,
  );
</script>

<template>
  <v-button
    class="notification-btn"
    :class="{
      'notification-btn--with-indicator': hasUnviewedNotification,
    }"
    :to="{ name: 'profile-notifications' }"
    appearance="text"
    color="white"
    size="normal"
    icon="bell"
    rounded
  />
</template>

<style lang="scss" scoped>
  .notification-btn {
    position: relative;

    &.v-button[data-size='normal'] {
      padding: 2px 0;
    }

    &--with-indicator {
      &:before {
        content: ' ';
        position: absolute;
        top: 4px;
        right: 2px;
        width: 8px;
        height: 8px;

        background-color: $secondary_red_light;
        border-radius: 50%;

        @media (max-width: $retina) {
          top: -6px;
        }
      }
    }
  }
</style>
