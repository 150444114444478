<script setup lang="ts">
  interface IProps {
    size?: 'extra' | 'large' | 'middle' | 'small';
    media?: 'large' | 'middle' | 'small';
    img?: boolean;
    link?: boolean;
  }

  const props = withDefaults(defineProps<IProps>(), {
    size: 'middle',
  });

  const config = useRuntimeConfig();
</script>
<template>
  <component
    :is="props.link ? 'router-link' : 'div'"
    :data-size="props.size"
    :data-media="props.media"
    class="logo"
    to="/"
  >
    <img
      v-if="props.img"
      :src="`https://vhjh3saedm.a.trbcdn.net/${['dev', 'test'].includes(config.public.environment) ? 'logo_dev.png' : 'logo.png'}`"
      alt="kinoflex.ru"
    />
    <NuxtImg v-else src="images/loading-logo.svg" />
  </component>
</template>
<style lang="scss">
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;

    &[data-size='small'] {
      img {
        width: 70px;
      }
    }

    &[data-size='middle'] {
      img {
        width: 106px;
      }
    }

    &[data-size='large'] {
      img {
        width: 140px;
      }
    }

    &[data-size='extra'] {
      img {
        width: 244px;
      }
    }

    &[data-media='small'] {
      @media (max-width: $retina) {
        img {
          width: 70px;
        }
      }
    }

    &[data-media='middle'] {
      @media (max-width: $retina) {
        img {
          width: 100px;
        }
      }
      @media (max-width: $mobile) {
        img {
          width: 85px;
        }
      }
    }

    &[data-media='large'] {
      @media (max-width: $retina) {
        img {
          width: 140px;
        }
      }
    }
  }
</style>
