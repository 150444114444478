<script lang="ts" setup>
  import VBrowseFilter from '~/components/header/VBrowseFilter.vue';
  import NotificationBtn from '~/components/header/NotificationBtn.vue';

  const VLogo = defineAsyncComponent(() => import('~/components/header/VLogo.vue'));
  const VMenu = defineAsyncComponent(() => import('~/components/header/VMenu.vue'));
  const VSearch = defineAsyncComponent(() => import('~/components/header/VSearch.vue'));
  const VProfile = defineAsyncComponent(() => import('~/components/header/VProfile.vue'));
  const VMobileButton = defineAsyncComponent(() => import('~/components/header/VMobileButton.vue'));

  const route = useRoute();
  const { mobileHeader: header } = useHeader();

  const pathWithBrowseFilters = ['browse-category', 'browse-genre-slug'];
</script>

<template>
  <header class="header container">
    <div class="header__container">
      <v-mobile-button class="header__burger" />
      <div v-if="header" class="header__logo mobile-header">{{ header }}</div>
      <v-logo v-else class="header__logo" media="middle" img link />
      <div class="header__spacer"></div>
      <v-menu />
      <div class="header__spacer"></div>
      <v-browse-filter v-if="pathWithBrowseFilters.includes(route.name)" class="header__mobile-el" />
      <notification-btn v-else class="header__mobile-el" />
      <v-search />
      <v-profile />
    </div>
  </header>
</template>

<style lang="scss" scoped>
  .header {
    position: fixed;
    top: 0;
    padding: 15px 0;
    background: radial-gradient(circle, rgba(18, 16, 17, 0.40242034313725494) 0%, rgba(148, 187, 233, 0) 100%);
    backdrop-filter: blur(10px);
    width: 100%;
    z-index: 999;

    &__container {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__spacer {
      margin: auto;
    }

    &__burger {
      display: none;
    }

    &__title {
      color: $color-primary;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
    }

    &__mobile-el {
      display: none;
    }

    @media (max-width: $retina) {
      padding: 13px 0;
      &__burger {
        display: block;
      }
      &__logo {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      &__mobile-el {
        display: inline-block;
      }
    }
  }

  .mobile-header {
    color: $main_white;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    @media (max-width: $retina) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 60%;
    }
  }
</style>
